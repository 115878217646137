import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import React, { forwardRef } from 'react'
import { useTracking } from '@babylon/web-platform-utils-tracking-react'

type ButtonProps = MuiButtonProps & {
  eventCategory?: string
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, eventCategory = '', onClick, ...props }, ref) => {
    const tracking = useTracking()

    const onClickTrackEvent = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      tracking.trackEvent({
        eventAction: 'click',
        eventCategory,
      })
      if (onClick) onClick(event)
    }

    return (
      <MuiButton {...props} ref={ref} onClick={onClickTrackEvent}>
        {children}
      </MuiButton>
    )
  }
)

Button.displayName = 'Button'
