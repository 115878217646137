import { ProcessedObservation } from '../../types';
import { LineData, Plot } from '../../Visualisations/Line';
import { lineColour } from '../../Visualisations/Line/Colors';
import { SingleValueData } from '../../Visualisations/SingleValue';
import { getValuesFromComponents } from './utils';

export const bloodPressureSingleValueProcessor = (
  observations: Array<ProcessedObservation>
): Array<SingleValueData> => {
  return observations.map((observation: ProcessedObservation) => {
    let value = '';
    let unit = '';

    if (observation.components) {
      unit = observation.components[0]?.quantity_code || '';
      const values = getValuesFromComponents(observation.components);
      value =
        values.systolic && values.diastolic
          ? `${values.systolic}/${values.diastolic}`
          : '';
    }

    const data: SingleValueData = {
      date: observation.timestamp,
      value: value,
      unit: unit,
      title: observation.observation_display,
    };

    return data;
  });
};

const getShortDate = (date: string) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

export const bloodPressureLineValueProcessor = (
  observations: Array<ProcessedObservation>
): Array<LineData> => {
  const lineData: LineData = {
    series: [],
    tooltips: [],
    labels: {},
  };

  const plotsFirst: Array<Plot> = [];
  const plotsSecond: Array<Plot> = [];
  const tooltips: Array<Plot> = [];

  observations.forEach((observation: ProcessedObservation) => {
    const components = observation.components || [];
    const values = getValuesFromComponents(components);
    const x = observation.timestamp ? getShortDate(observation.timestamp) : '';

    plotsFirst.push({ x, y: Number(values.systolic) });
    plotsSecond.push({ x, y: Number(values.diastolic) });

    const unit = components[0]?.quantity_unit || '';
    const label =
      values.systolic && values.diastolic
        ? `${values.systolic}/${values.diastolic}${unit}`
        : '';
    tooltips.push({ x, y: Number(values.systolic), label });
  });

  lineData.series?.push({
    plots: plotsFirst,
    label: '',
    shape: 'circle',
    color: lineColour[0],
  });

  lineData.series?.push({
    plots: plotsSecond,
    label: '',
    shape: 'square',
    color: lineColour[1],
  });

  lineData.tooltips = tooltips;
  return [lineData];
};
