import * as Theme from '../../build/dna/defaultTheme'

export const DefaultTheme = {
  dark: Theme.DARK,
  colors: {
    brand: {
      primary: Theme.COLORS_BRAND_PRIMARY,
      primaryFocus: Theme.COLORS_BRAND_PRIMARY_FOCUS,
      secondary: Theme.COLORS_BRAND_SECONDARY,
      tertiary: Theme.COLORS_BRAND_TERTIARY,
    },
    text: {
      heading: Theme.COLORS_TEXT_HEADING,
      body: Theme.COLORS_TEXT_BODY,
      inverted: Theme.COLORS_TEXT_INVERTED,
    },
    status: {
      success: Theme.COLORS_STATUS_SUCCESS,
      warning: Theme.COLORS_STATUS_WARNING,
      error: Theme.COLORS_STATUS_ERROR,
    },
    functional: {
      inactive: Theme.COLORS_FUNCTIONAL_INACTIVE,
      graphic: Theme.COLORS_FUNCTIONAL_GRAPHIC,
      inverted: Theme.COLORS_FUNCTIONAL_INVERTED,
      divider: Theme.COLORS_FUNCTIONAL_DIVIDER,
    },
    background: {
      backgroundPrimary: Theme.COLORS_BACKGROUND_BACKGROUND_PRIMARY,
      backgroundSecondary: Theme.COLORS_BACKGROUND_BACKGROUND_SECONDARY,
      buttonSecondary: Theme.COLORS_BACKGROUND_BUTTON_SECONDARY,
      bannerInteractive: Theme.COLORS_BACKGROUND_BANNER_INTERACTIVE,
      bannerError: Theme.COLORS_BACKGROUND_BANNER_ERROR,
      bannerSuccess: Theme.COLORS_BACKGROUND_BANNER_SUCCESS,
      bannerWarning: Theme.COLORS_BACKGROUND_BANNER_WARNING,
      bannerNeutral: Theme.COLORS_BACKGROUND_BANNER_NEUTRAL,
    },
  },
  radius: {
    0: Theme.RADIUS_0,
    1: Theme.RADIUS_1,
    2: Theme.RADIUS_2,
    3: Theme.RADIUS_3,
    4: Theme.RADIUS_4,
    circle: Theme.RADIUS_CIRCLE_0,
  },
  spacing: {
    0: Theme.SPACING_0,
    1: Theme.SPACING_1,
    2: Theme.SPACING_2,
    3: Theme.SPACING_3,
    4: Theme.SPACING_4,
    5: Theme.SPACING_5,
    6: Theme.SPACING_6,
    7: Theme.SPACING_7,
    8: Theme.SPACING_8,
    9: Theme.SPACING_9,
    10: Theme.SPACING_10,
    11: Theme.SPACING_11,
    12: Theme.SPACING_12,
    13: Theme.SPACING_13,
    14: Theme.SPACING_14,
    15: Theme.SPACING_15,
    16: Theme.SPACING_16,
  },
  typography: {
    heading: {
      xxl: {
        typeface: Theme.TYPOGRAPHY_HEADING_XXL_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_XXL_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_XXL_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_XXL_LINE_HEIGHT,
      },
      xl: {
        typeface: Theme.TYPOGRAPHY_HEADING_XL_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_XL_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_XL_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_XL_LINE_HEIGHT,
      },
      lg: {
        typeface: Theme.TYPOGRAPHY_HEADING_LG_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_LG_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_LG_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_LG_LINE_HEIGHT,
      },
      md: {
        typeface: Theme.TYPOGRAPHY_HEADING_MD_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_MD_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_MD_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_MD_LINE_HEIGHT,
      },
      sm: {
        typeface: Theme.TYPOGRAPHY_HEADING_SM_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_SM_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_SM_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_SM_LINE_HEIGHT,
      },
      xs: {
        typeface: Theme.TYPOGRAPHY_HEADING_XS_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_XS_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_XS_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_XS_LINE_HEIGHT,
      },
      xxs: {
        typeface: Theme.TYPOGRAPHY_HEADING_XXS_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_XXS_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_XXS_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_XXS_LINE_HEIGHT,
      },
      xxxs: {
        typeface: Theme.TYPOGRAPHY_HEADING_XXXS_TYPEFACE,
        weight: Theme.TYPOGRAPHY_HEADING_XXXS_WEIGHT,
        size: Theme.TYPOGRAPHY_HEADING_XXXS_SIZE,
        lineHeight: Theme.TYPOGRAPHY_HEADING_XXXS_LINE_HEIGHT,
      },
    },
    body: {
      typeface: Theme.TYPOGRAPHY_BODY_TYPEFACE,
      weight: Theme.TYPOGRAPHY_BODY_WEIGHT,
      size: Theme.TYPOGRAPHY_BODY_SIZE,
      lineHeight: Theme.TYPOGRAPHY_BODY_LINE_HEIGHT,
    },
    caption: {
      typeface: Theme.TYPOGRAPHY_CAPTION_TYPEFACE,
      weight: Theme.TYPOGRAPHY_CAPTION_WEIGHT,
      size: Theme.TYPOGRAPHY_CAPTION_SIZE,
      lineHeight: Theme.TYPOGRAPHY_CAPTION_LINE_HEIGHT,
    },
    link: {
      standalone: {
        typeface: Theme.TYPOGRAPHY_LINK_STANDALONE_TYPEFACE,
        weight: Theme.TYPOGRAPHY_LINK_STANDALONE_WEIGHT,
        size: Theme.TYPOGRAPHY_LINK_STANDALONE_SIZE,
        lineHeight: Theme.TYPOGRAPHY_LINK_STANDALONE_LINE_HEIGHT,
      },
      inline: {
        typeface: Theme.TYPOGRAPHY_LINK_INLINE_TYPEFACE,
        weight: Theme.TYPOGRAPHY_LINK_INLINE_WEIGHT,
        size: Theme.TYPOGRAPHY_LINK_INLINE_SIZE,
        lineHeight: Theme.TYPOGRAPHY_LINK_INLINE_LINE_HEIGHT,
        decoration: Theme.TYPOGRAPHY_LINK_INLINE_DECORATION,
      },
      caption: {
        typeface: Theme.TYPOGRAPHY_LINK_CAPTION_TYPEFACE,
        weight: Theme.TYPOGRAPHY_LINK_CAPTION_WEIGHT,
        size: Theme.TYPOGRAPHY_LINK_CAPTION_SIZE,
        lineHeight: Theme.TYPOGRAPHY_LINK_CAPTION_LINE_HEIGHT,
        decoration: Theme.TYPOGRAPHY_LINK_CAPTION_DECORATION,
      },
    },
  },
}
