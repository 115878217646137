import { defineMessages } from 'react-intl';

const baseId = 'hgc-blood-sugar';

export const messages = defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Blood Sugar',
  },
});
