import {
  getSingleValueComponent,
  getLineComponent,
  ComponentConfig,
} from '../helpers';
import { messages } from './messages';
import {
  bloodPressureSingleValueProcessor,
  bloodPressureLineValueProcessor,
} from './processors';

const config: ComponentConfig = {
  dataSource: 'BLOOD_PRESSURE',
  visualisationSettings: { title: messages.title, overrideTitle: true },
};

export const BloodPressureSingle = getSingleValueComponent({
  ...config,
  additionalDataTransforms: [bloodPressureSingleValueProcessor],
});

export const BloodPressureLine = getLineComponent({
  ...config,
  additionalDataTransforms: [bloodPressureLineValueProcessor],
});
