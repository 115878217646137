// Please do not update this file directly. It is generated when running yarn themeBuilder
// The themes included are based on the directories present here: src/theme/tokens/brands
import { DefaultTheme as bupaLight } from './bupa/DefaultTheme'
import { DarkTheme as bupaDark } from './bupa/DarkTheme'
import { DefaultTheme as dnaLight } from './dna/DefaultTheme'
import { DarkTheme as dnaDark } from './dna/DarkTheme'
import { DefaultTheme as telusLight } from './telus/DefaultTheme'
import { DarkTheme as telusDark } from './telus/DarkTheme'

export const ThemeDict = {
  bupa: {
    light: bupaLight,
    dark: bupaDark,
  },
  dna: {
    light: dnaLight,
    dark: dnaDark,
  },
  telus: {
    light: telusLight,
    dark: telusDark,
  },
}
