import { Typography, Skeleton, Stack, Alert } from '@babylon/portal-ui';
import { FormattedDate, useIntl } from 'react-intl';
import {
  ProcessedObservation,
  VisualisationConfig,
  VisualisationComponentProps,
} from '../../types';
import { messages } from './messages';

export interface SingleValueData {
  value?: string | number;
  unit?: string;
  title?: string;
  date?: string;
}

const Loader = () => (
  <>
    <Typography variant="h6">
      <Skeleton />
    </Typography>
    <Typography variant="h4">
      <Skeleton />
    </Typography>
    <Typography>
      <Skeleton />
    </Typography>
  </>
);

const getValuesFromObservation = (
  observation: ProcessedObservation
): SingleValueData => {
  return {
    value: observation.quantity_value
      ? observation.quantity_value
      : observation.codeableConcept_display,
    unit: observation.quantity_unit,
    title: observation.observation_display,
    date: observation.timestamp,
  };
};

export const SingleValueComponent = (
  props: VisualisationComponentProps<SingleValueData | ProcessedObservation>
) => {
  const { data, visualisationSettings } = props;
  const actualValue = Array.isArray(data) ? data[0] : data;
  const { formatMessage: fm } = useIntl();

  const displayValues =
    actualValue === undefined
      ? undefined
      : 'value' in actualValue
      ? actualValue
      : getValuesFromObservation(actualValue as ProcessedObservation);

  const defaultTitle = visualisationSettings?.title
    ? typeof visualisationSettings?.title === 'string'
      ? visualisationSettings.title
      : fm(visualisationSettings.title)
    : '';

  const title =
    !visualisationSettings?.overrideTitle && displayValues?.title
      ? displayValues?.title
      : defaultTitle;
  const NO_DATA = (
    <Alert icon={false} severity="info">
      {fm(messages.not_applicable)}
    </Alert>
  );

  return (
    <>
      {visualisationSettings?.title && (
        <Typography variant="h6">{title}</Typography>
      )}
      {displayValues?.value ? (
        <>
          <Stack direction="row" alignItems="baseline" flexWrap="wrap">
            <Typography
              variant="h4"
              style={{
                fontSize: displayValues?.unit ? undefined : '125%', // Show smaller font size if value has no unit
              }}
            >
              {typeof displayValues.value === 'number'
                ? displayValues.value.toFixed(1)
                : displayValues.value}
            </Typography>
            {displayValues.unit && (
              <Typography variant="h5">{displayValues.unit}</Typography>
            )}
          </Stack>
          {displayValues.date && (
            <Typography>
              <FormattedDate
                value={displayValues.date}
                dateStyle="medium"
                timeStyle="short"
              />
            </Typography>
          )}
        </>
      ) : (
        NO_DATA
      )}
    </>
  );
};

export const SingleValue: VisualisationConfig<SingleValueData> = {
  LoaderComponent: Loader,
  VisualisationComponent: SingleValueComponent,
};
