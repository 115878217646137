/**
 * Do not edit directly
 * Generated on Thu, 11 Aug 2022 14:02:26 GMT
 */

export const DARK = true;
export const COLORS_BRAND_PRIMARY = "#0079C8";
export const COLORS_BRAND_PRIMARY_FOCUS = "#0D1846";
export const COLORS_BRAND_SECONDARY = "#0652AE";
export const COLORS_BRAND_TERTIARY = "#D02670";
export const COLORS_TEXT_HEADING = "#21272A";
export const COLORS_TEXT_BODY = "#21272A";
export const COLORS_TEXT_INVERTED = "#FFFFFF";
export const COLORS_STATUS_SUCCESS = "#1B883C";
export const COLORS_STATUS_WARNING = "#C85204";
export const COLORS_STATUS_ERROR = "#D60023";
export const COLORS_FUNCTIONAL_INACTIVE = "#BDC3C9";
export const COLORS_FUNCTIONAL_GRAPHIC = "#929BA2";
export const COLORS_FUNCTIONAL_DIVIDER = "#DDE1E6";
export const COLORS_FUNCTIONAL_INVERTED = "#FFFFFF";
export const COLORS_FUNCTIONAL_OVERLAY = "rgba(0,0,0,0.4)";
export const COLORS_BACKGROUND_BACKGROUND_PRIMARY = "#FFFFFF";
export const COLORS_BACKGROUND_BACKGROUND_SECONDARY = "#F3F5F8";
export const COLORS_BACKGROUND_BUTTON_SECONDARY = "#DDEDF8";
export const COLORS_BACKGROUND_BANNER_INTERACTIVE = "#DDEDF8";
export const COLORS_BACKGROUND_BANNER_ERROR = "#FCECF4";
export const COLORS_BACKGROUND_BANNER_SUCCESS = "#DEFBE6";
export const COLORS_BACKGROUND_BANNER_WARNING = "#FBEACF";
export const COLORS_BACKGROUND_BANNER_NEUTRAL = "#EBEEF2";