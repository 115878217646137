import { Flyout, FlyoutProps } from 'victory';

export const FlyoutWithLine: React.FC<FlyoutProps> = (props) => {
  const { x } = props;

  if (!x) {
    return null;
  }

  return (
    <>
      <line
        // top of the line
        x1={x}
        y1={50}
        // bottom of the line
        x2={x}
        y2={300} // 280 is the height of the graph
        stroke="grey"
        strokeWidth={1}
      />
      <Flyout {...props} />
    </>
  );
};
