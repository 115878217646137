import {
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
  LineSegment,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryBar,
  VictoryTooltip,
} from 'victory';
import {
  ProcessedObservation,
  VisualisationComponentProps,
  VisualisationConfig,
} from '../../types';
import { Alert, Typography } from '@babylon/portal-ui';
import { renderSeries } from './RenderSeries';
import { LineData } from './types';
import { FlyoutWithLine } from './Flyout';
import { standardLineProcessor } from './ProcessedObservationTransform';
import { useIntl } from 'react-intl';
export * from './types';
export * from './Colors';
import { messages } from './messages';

const Loader = () => (
  <VictoryChart domainPadding={50} width={900}>
    <VictoryAxis
      dependentAxis
      tickLabelComponent={
        <VictoryLabel
          backgroundPadding={2}
          backgroundStyle={{ fill: '#00000010' }}
          style={{ fill: 'none' }}
        />
      }
      style={{ axis: { stroke: '#00000014', strokeWidth: 3 } }}
    />
    <VictoryAxis
      tickLabelComponent={
        <VictoryLabel
          backgroundPadding={2}
          backgroundStyle={{ fill: '#00000011' }}
          style={{ fill: 'none' }}
        />
      }
      style={{ axis: { stroke: '#00000014', strokeWidth: 3 } }}
    />
    <VictoryLine
      interpolation="natural"
      style={{
        data: { stroke: '#00000017', strokeWidth: 8, strokeLinecap: 'round' },
      }}
      data={[
        { x: 1, y: 2 },
        { x: 2, y: 3 },
        { x: 3, y: 5 },
        { x: 4, y: 4 },
        { x: 5, y: 7 },
      ]}
    />
  </VictoryChart>
);

const LineChartComponent = (props: VisualisationComponentProps<LineData>) => {
  const { data, visualisationSettings } = props;
  const { formatMessage: fm } = useIntl();

  const defaultTitle = visualisationSettings?.title
    ? typeof visualisationSettings?.title === 'string'
      ? visualisationSettings.title
      : fm(visualisationSettings.title)
    : '';

  const lineData =
    data && data[0] !== undefined && 'series' in data[0]
      ? data[0]
      : standardLineProcessor(data as Array<ProcessedObservation>);

  const title =
    !visualisationSettings?.overrideTitle && lineData.labels.title
      ? lineData.labels.title
      : defaultTitle;

  const NO_DATA = (
    <Alert icon={false} severity="info">
      {fm(messages.not_applicable)}
    </Alert>
  );

  return lineData === undefined ? (
    NO_DATA
  ) : (
    <>
      <Typography variant="h6">{title}</Typography>
      <VictoryChart
        theme={VictoryTheme.material}
        width={900}
        domainPadding={{ y: 50 }}
        containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
      >
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickCount={3}
          tickLabelComponent={
            <VictoryLabel dy={-6} textAnchor="end" verticalAnchor={'end'} />
          }
          offsetX={23}
          gridComponent={<LineSegment x1={0} x2={2000} />}
          style={{
            grid: {
              stroke: '#728492',
              strokeDasharray: '1',
              strokeLinecap: 'butt',
              strokeWidth: '0.5',
            },
            axis: {
              display: 'none',
            },
            ticks: {
              display: 'none',
            },
            tickLabels: {
              fontSize: 11,
              lineHeight: 20,
              fill: '#44525F',
            },
          }}
        />
        <VictoryAxis
          fixLabelOverlap
          scale="time"
          crossAxis
          axisComponent={<LineSegment x1={0} x2={2000} />}
          style={{
            grid: {
              display: 'none',
            },
            axis: {
              stroke: '#D7DADC',
            },
            ticks: {
              stroke: '#728492',
              strokeWidth: 0.5,
              size: 5,
            },
            tickLabels: {
              fontSize: '14px',
              lineHeight: '20px',
            },
          }}
          padding={{
            top: 0,
            bottom: 48,
            left: 48,
            right: 48,
          }}
        />
        {lineData.series?.map(renderSeries)}
        {/* This is only here for tooltips and is otherwise not visible */}
        {lineData.tooltips && (
          <VictoryBar
            labels={(datum) => datum.label}
            // Having this label component in a separate file was causing it to not render
            // on some graphs. No idea why.
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                flyoutComponent={<FlyoutWithLine />}
                flyoutPadding={{ left: 18, right: 18, top: 12, bottom: 12 }}
                flyoutStyle={{
                  fill: '#F7F7F8',
                  stroke: '#D7DADC',
                  strokeWidth: 0.5,
                }}
                flyoutWidth={126}
                cornerRadius={10}
                pointerWidth={0.01}
                pointerLength={0}
                style={{ textAnchor: 'start', verticalAnchor: 'end' }}
                labelComponent={
                  <VictoryLabel
                    lineHeight={[1.6, 1.2]}
                    style={[
                      {
                        fill: '#32383D',
                        fontWeight: '600',
                      },
                      {
                        fill: '#44525F',
                        fontSize: 12,
                        lineHeight: 20,
                      },
                    ]}
                  />
                }
              />
            }
            name="tooltipsBar"
            data={lineData.tooltips}
            style={{
              data: {
                fill: 'transparent',
              },
              labels: {
                fill: 'transparent',
              },
            }}
          />
        )}
      </VictoryChart>
    </>
  );
};

export const Line: VisualisationConfig = {
  LoaderComponent: Loader,
  VisualisationComponent: LineChartComponent,
};
