import { ProcessedObservation } from '../../types';
import { LineData, Plot } from './types';
import { lineColour } from './Colors';

const getShortDate = (date: string) => {
  const jsDate = new Date(date);
  return jsDate.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

/**
 * used for most standard value based observations
 */
export const standardLineProcessor = (
  observations: Array<ProcessedObservation>
): LineData => {
  const title = observations[0]?.observation_display || '';
  const plots: Array<Plot> = [];
  const tooltips: Array<Plot> = [];

  observations.forEach((observation: ProcessedObservation) => {
    const labelValue =
      typeof observation.quantity_value === 'number'
        ? observation.quantity_value.toFixed(1)
        : observation.quantity_value;
    const label = `${labelValue}${observation.quantity_unit}`;
    const x = observation.timestamp ? getShortDate(observation.timestamp) : '';
    const y = Number(observation.quantity_value);

    plots.push({ x, y });
    tooltips.push({ x, y, label });
  });

  const lineData: LineData = {
    series: [
      {
        label: observations[0]?.observation_display || '',
        shape: 'circle',
        color: lineColour[0],
        plots,
      },
    ],
    labels: {
      title,
    },
    tooltips: tooltips,
  };
  return lineData;
};
