import {
  GetPatientObservationsQuery,
  Observation,
  ObservationFragment,
  ObservationComponentFragment,
  Coding,
  CodeableConcept,
} from '../generated/federated';
import { ProcessedObservation } from '../types';

export const getObservations = (data: GetPatientObservationsQuery) => {
  const patient =
    data.patient?.__typename === 'Patient' ? data.patient : undefined;
  const nodes =
    patient?.observations?.__typename === 'ObservationConnection'
      ? patient.observations.nodes
      : [];
  return nodes;
};

export const getDerivedFromObservation = (
  observation: Observation | null
): Array<ObservationFragment> => {
  if (observation === null) {
    return [];
  } else {
    return observation.derivedFrom?.__typename ===
      'ObservationDerivedFromResultConnection' && observation.derivedFrom.nodes
      ? (observation.derivedFrom.nodes as Array<ObservationFragment>) //need to translate it back to the fragment type
      : [];
  }
};

export const getComponentsFromObservation = (
  observation: ObservationFragment | null
): Array<ObservationComponentFragment> => {
  if (observation === null) {
    return [];
  } else {
    const nodes =
      observation.components?.__typename === 'ObservationComponentConnection' &&
      observation.components.nodes;

    if (nodes) {
      const nds = nodes.filter(
        (node) => node?.__typename === 'ObservationComponent'
      ) as Array<ObservationComponentFragment>; // not sure why the filtered return type includes null
      return nds;
    } else {
      return [];
    }
  }
};

export const getTrustFromObservation = (
  observation: ObservationFragment | null
): { trust_category?: string; trust_class?: string } => {
  if (observation === null) {
    return {};
  } else {
    const trust =
      observation.trust?.__typename === 'Trust' ? observation.trust : {};

    return {
      trust_category: trust.category || undefined,
      trust_class: trust.class || undefined,
    };
  }
};

export const getValueQuantityFromObservation = (
  observation: ObservationComponentFragment | ObservationFragment | null
): {
  quantity_value?: string | number;
  quantity_unit?: string;
  quantity_code?: string;
} => {
  if (observation === null) {
    return {};
  } else {
    const valueQuantity =
      observation.valueQuantity?.__typename === 'Quantity'
        ? observation.valueQuantity
        : {};

    return {
      quantity_code: valueQuantity.code || undefined,
      quantity_unit: valueQuantity.unit || undefined,
      quantity_value: valueQuantity.value || undefined,
    };
  }
};

export const getCodeValue = (
  code: Coding | null | undefined
): { code?: string; display?: string } => {
  if (code === null || code === undefined) {
    return {};
  } else {
    return {
      code: code.code || undefined,
      display: code.display?.text || undefined,
    };
  }
};

export const getFirstCodingsValueFromCodeableConcept = (
  codeableConcept: CodeableConcept | null | undefined
): { code?: string; display?: string } => {
  if (!codeableConcept) {
    return {};
  } else {
    const code = codeableConcept.codings?.nodes[0];
    if (code) {
      return getCodeValue(code);
    } else {
      return {};
    }
  }
};

export const getCodingFromCodeableConcept = (
  codeableConcept: CodeableConcept
): { code?: string; display?: string } => {
  if (!codeableConcept) {
    return {};
  } else {
    const code =
      codeableConcept.coding?.__typename === 'Coding'
        ? codeableConcept.coding
        : undefined;
    if (code) {
      return getCodeValue(code);
    } else {
      return {};
    }
  }
};

export const getCodeableConceptFromObservation = (
  observation: ObservationFragment | null
): { codableConcept_code?: string; codeableConcept_display?: string } => {
  if (!observation?.valueCodeableConcept) {
    return {};
  }

  const { code, display } = getCodingFromCodeableConcept(
    observation.valueCodeableConcept as CodeableConcept
  );

  return {
    codableConcept_code: code,
    codeableConcept_display: display,
  };
};

export const getCodeFromObservation = (
  observation: ObservationFragment | ObservationComponentFragment | null
): { observation_code?: string; observation_display?: string } => {
  if (!observation?.code) {
    return {};
  }

  const observationCode = observation.code;

  const { code, display } =
    observation.__typename === 'ObservationComponent'
      ? getCodingFromCodeableConcept(observationCode as CodeableConcept)
      : getFirstCodingsValueFromCodeableConcept(
          observationCode as CodeableConcept
        );
  return {
    observation_code: code,
    observation_display: display,
  };
};

export const processObservation = (
  observation: ObservationFragment | null | ObservationComponentFragment
): ProcessedObservation => {
  const processed: ProcessedObservation = {};

  if (observation?.__typename === 'Observation') {
    processed.components =
      getComponentsFromObservation(observation).map(processObservation);
    processed.derivedFrom = getDerivedFromObservation(
      observation as Observation // we don't have derived from on the fragment so have to cast it to observation type
    ).map(processObservation);

    if (observation.trust) {
      const { trust_class, trust_category } =
        getTrustFromObservation(observation);
      processed.trust_class = trust_class;
      processed.trust_category = trust_category;
    }

    if (observation.valueCodeableConcept) {
      const { codableConcept_code, codeableConcept_display } =
        getCodeableConceptFromObservation(observation);
      processed.codableConcept_code = codableConcept_code;
      processed.codeableConcept_display = codeableConcept_display;
    }

    processed.timestamp = observation.timestamp;
    processed.resourceUri = observation.resourceUri
      ? observation.resourceUri
      : undefined;
  }

  if (observation?.code) {
    const { observation_code, observation_display } =
      getCodeFromObservation(observation);
    processed.observation_code = observation_code;
    processed.observation_display = observation_display;
  }

  if (observation?.valueQuantity) {
    const { quantity_value, quantity_unit, quantity_code } =
      getValueQuantityFromObservation(observation);
    processed.quantity_code = quantity_code;
    processed.quantity_unit = quantity_unit;
    processed.quantity_value = quantity_value;
  }

  return processed;
};
