import { defineMessages } from 'react-intl';

const baseId = 'hgc-single-value';

export const messages = defineMessages({
  not_applicable: {
    id: `${baseId}.not_applicable`,
    defaultMessage: 'N/A',
  },
});
