import { HGC } from './HGC';
import {
  DataProperties,
  HealthGraphComponent,
  ProcessedObservation,
  VisualisationSettings,
} from '../types';
import { DataSourceKeys } from '../Data/DataSources';
import { DefaultContext } from '@apollo/client';

export interface ComponentConfig<T = ProcessedObservation> {
  dataSource: DataSourceKeys;
  visualisationSettings?: VisualisationSettings;
  additionalDataTransforms?: Array<(result: any) => Array<T>>;
}

export const getSingleValueComponent = <T,>(
  config: ComponentConfig<T>
): HealthGraphComponent => {
  const { dataSource, visualisationSettings, additionalDataTransforms } =
    config;

  const SingleValueComponent: HealthGraphComponent = (props: {
    data: DataProperties;
    queryContext?: DefaultContext;
  }) => (
    <HGC<T>
      dataProperties={props.data}
      dataSource={dataSource}
      visualisationType={'SingleValue'}
      visualisationSettings={visualisationSettings}
      additionalDataTransforms={additionalDataTransforms}
      queryContext={props.queryContext}
    />
  );

  return SingleValueComponent;
};

export const getLineComponent = <T,>(
  config: ComponentConfig<T>
): HealthGraphComponent => {
  const { dataSource, visualisationSettings, additionalDataTransforms } =
    config;

  const LineComponent: HealthGraphComponent = (props: {
    data: DataProperties;
    queryContext?: DefaultContext;
  }) => (
    <HGC<T>
      dataProperties={props.data}
      dataSource={dataSource}
      dataSourceSettings={{ first: 1000 }}
      visualisationType={'Line'}
      visualisationSettings={visualisationSettings}
      additionalDataTransforms={additionalDataTransforms}
      queryContext={props.queryContext}
    />
  );

  return LineComponent;
};
