/**
 * Do not edit directly
 * Generated on Thu, 11 Aug 2022 14:02:26 GMT
 */

export const DARK = true;
export const COLORS_BRAND_PRIMARY = "#8F75FF";
export const COLORS_BRAND_PRIMARY_FOCUS = "#B3AFFF";
export const COLORS_BRAND_SECONDARY = "#5565F7";
export const COLORS_BRAND_TERTIARY = "#13BCF1";
export const COLORS_TEXT_HEADING = "#FFFFFF";
export const COLORS_TEXT_BODY = "#BABFC4";
export const COLORS_TEXT_INVERTED = "#121212";
export const COLORS_STATUS_SUCCESS = "#10B035";
export const COLORS_STATUS_WARNING = "#E56800";
export const COLORS_STATUS_ERROR = "#FA3E00";
export const COLORS_FUNCTIONAL_INACTIVE = "#979DA1";
export const COLORS_FUNCTIONAL_GRAPHIC = "#777777";
export const COLORS_FUNCTIONAL_DIVIDER = "#6B6B6B";
export const COLORS_FUNCTIONAL_INVERTED = "#121212";
export const COLORS_FUNCTIONAL_OVERLAY = "rgba(0,0,0,0.6)";
export const COLORS_BACKGROUND_BACKGROUND_PRIMARY = "#1E1E1E";
export const COLORS_BACKGROUND_BACKGROUND_SECONDARY = "#121212";
export const COLORS_BACKGROUND_BUTTON_SECONDARY = "#32313F";
export const COLORS_BACKGROUND_BANNER_INTERACTIVE = "#1B1921";
export const COLORS_BACKGROUND_BANNER_ERROR = "#221A18";
export const COLORS_BACKGROUND_BANNER_SUCCESS = "#1A211B";
export const COLORS_BACKGROUND_BANNER_WARNING = "#211D1A";
export const COLORS_BACKGROUND_BANNER_NEUTRAL = "#282828";