import { DataSourceConfig } from '../types';

export const DataSources: Record<string, DataSourceConfig> = {
  BASELINE_PEAK_FLOW: { FHIR_CODE: '6KIehMp_Eh' },
  PEAK_FLOW: { FHIR_CODE: 'm9Gq6k2S6D' },
  BLOOD_OXYGEN: { FHIR_CODE: 'iWW1T2ld21' },
  BLOOD_PRESSURE: { FHIR_CODE: 'DcuIExT7O8' },
  BLOOD_SUGAR: { FHIR_CODE: 'BXPPBhmkeC' },
  BMI: { FHIR_CODE: 'AfaKosubFW' },
  HBA1C: { FHIR_CODE: 'EnJCulUvF3' },
  HEIGHT: { FHIR_CODE: 'D_ViDKoBWF' },
  WEIGHT: { FHIR_CODE: 'ykWNn2DwyB' },
  SMOKING_STATUS: { FHIR_CODE: 'ZE5ErF3JBH' }, //may need to support multiple smoking codes
  TEMPERATURE: { FHIR_CODE: 'aE8dL8InQy' },
  WAIST_CIRCUMFERENCE: { FHIR_CODE: '4z_OQ0TK80' },
};

export type DataSourceKeys = keyof typeof DataSources;
