import { defineMessages } from 'react-intl';

const baseId = 'hgc-temperature';

export const messages = defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Temperature',
  },
});
