import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  PaletteMode,
} from '@mui/material'
import React from 'react'
import { ThemeDict } from './themes'
// Ideally these would be imported here rather than individual experiences, but jest has trouble parsing the files
// import '@babylon/fonts/Visuelt'
// import '@babylon/fonts/Inter'
// import '@babylon/fonts/Montserrat'
import { TrackingProvider } from '@babylon/web-platform-utils-tracking-react'

type brandsLowerCase = 'dna' | 'telus' | 'bupa'
type brands = 'DNA' | 'Telus' | 'Bupa'
type colorMode = 'light' | 'dark'

const getDesignTokens = (curBrand: brands, darkMode: colorMode) => {
  const brand = curBrand.toLowerCase() as brandsLowerCase
  const mode = darkMode === 'dark' ? 'dark' : 'light'
  return (ThemeDict[brand] && ThemeDict[brand][mode]) || ThemeDict.dna.light
}

const setupTheme = (tokens: typeof ThemeDict.dna.light) => {
  return {
    palette: {
      tonalOffset: 0,
      mode: (tokens.dark ? 'dark' : 'light') as PaletteMode,
      primary: {
        main: tokens.colors.brand.primary,
      },
      primaryFocus: {
        main: tokens.colors.brand.primaryFocus,
      },
      secondary: {
        main: tokens.colors.brand.secondary,
      },
      tertiary: {
        main: tokens.colors.brand.tertiary,
      },
      error: {
        main: tokens.colors.status.error,
      },
      warning: {
        main: tokens.colors.status.warning,
      },
      info: {
        main: tokens.colors.background.bannerInteractive,
      },
      success: {
        main: tokens.colors.status.success,
      },
      text: {
        primary: tokens.colors.text.body,
        disabled: tokens.colors.functional.inactive,
        heading: tokens.colors.text.heading,
        inverted: tokens.colors.text.inverted,
      },
      divider: tokens.colors.functional.divider,
      functional: {
        inactive: tokens.colors.functional.inactive,
        graphic: tokens.colors.functional.graphic,
        inverted: tokens.colors.functional.inverted,
      },
      background: {
        paper: tokens.colors.background.backgroundPrimary,
        default: tokens.colors.background.backgroundSecondary,
        buttonSecondary: tokens.colors.background.buttonSecondary,
        bannerInteractive: tokens.colors.background.bannerInteractive,
        bannerError: tokens.colors.background.bannerError,
        bannerSuccess: tokens.colors.background.bannerSuccess,
        bannerWarning: tokens.colors.background.bannerWarning,
        bannerNeutral: tokens.colors.background.bannerNeutral,
      },
    },
    radius: {
      0: tokens.radius[0],
      1: tokens.radius[1],
      2: tokens.radius[2],
      3: tokens.radius[3],
      4: tokens.radius[4],
      circle: tokens.radius.circle,
    },
    customSpacing: {
      0: tokens.spacing[0],
      1: tokens.spacing[1],
      2: tokens.spacing[2],
      3: tokens.spacing[3],
      4: tokens.spacing[4],
      5: tokens.spacing[5],
      6: tokens.spacing[6],
      7: tokens.spacing[7],
      8: tokens.spacing[8],
      9: tokens.spacing[9],
      10: tokens.spacing[10],
      11: tokens.spacing[11],
      12: tokens.spacing[12],
      13: tokens.spacing[13],
      14: tokens.spacing[14],
      15: tokens.spacing[15],
      16: tokens.spacing[16],
    },
    typography: {
      fontFamily: `${tokens.typography.body.typeface}, sans-serif`,
      h1: {
        fontFamily: `${tokens.typography.heading.xxl.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.xxl.weight,
        fontSize: tokens.typography.heading.xxl.size,
        lineHeight: tokens.typography.heading.xxl.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      h2: {
        fontFamily: `${tokens.typography.heading.xl.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.xl.weight,
        fontSize: tokens.typography.heading.xl.size,
        lineHeight: tokens.typography.heading.xl.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      h3: {
        fontFamily: `${tokens.typography.heading.lg.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.lg.weight,
        fontSize: tokens.typography.heading.lg.size,
        lineHeight: tokens.typography.heading.lg.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      h4: {
        fontFamily: `${tokens.typography.heading.md.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.md.weight,
        fontSize: tokens.typography.heading.md.size,
        lineHeight: tokens.typography.heading.md.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      h5: {
        fontFamily: `${tokens.typography.heading.sm.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.sm.weight,
        fontSize: tokens.typography.heading.sm.size,
        lineHeight: tokens.typography.heading.sm.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      h6: {
        fontFamily: `${tokens.typography.heading.xs.typeface}, sans-serif`,
        fontWeight: tokens.typography.heading.xs.weight,
        fontSize: tokens.typography.heading.xs.size,
        lineHeight: tokens.typography.heading.xs.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.heading,
      },
      caption: {
        fontFamily: `${tokens.typography.caption.typeface}, sans-serif`,
        fontWeight: tokens.typography.caption.weight,
        fontSize: tokens.typography.caption.size,
        lineHeight: tokens.typography.caption.lineHeight,
        letterSpacing: 0,
        color: tokens.colors.text.body,
      },
      link: {
        standalone: {
          fontFamily: `${tokens.typography.link.standalone.typeface}, sans-serif`,
          fontWeight: tokens.typography.link.standalone.weight,
          fontSize: tokens.typography.link.standalone.size,
          lineHeight: tokens.typography.link.standalone.lineHeight,
          letterSpacing: 0,
          color: tokens.colors.brand.primary,
        },
        inline: {
          fontFamily: `${tokens.typography.link.inline.typeface}, sans-serif`,
          fontWeight: tokens.typography.link.inline.weight,
          fontSize: tokens.typography.link.inline.size,
          lineHeight: tokens.typography.link.inline.lineHeight,
          letterSpacing: 0,
          color: tokens.colors.brand.primary,
        },
        caption: {
          fontFamily: `${tokens.typography.link.inline.typeface}, sans-serif`,
          fontWeight: tokens.typography.link.inline.weight,
          fontSize: tokens.typography.link.inline.size,
          lineHeight: tokens.typography.link.inline.lineHeight,
          letterSpacing: 0,
          color: tokens.colors.brand.primary,
        },
      },
    },
  }
}

type MuiThemeProviderProps = {
  brand: brands
  mode: colorMode
  children: React.ReactNode
  name?: string
  version?: string
}

export const MuiThemeProvider = ({
  brand,
  mode,
  name = '',
  version = '',
  children,
}: MuiThemeProviderProps) => {
  const tokens = getDesignTokens(brand, mode)
  const brandedTheme = setupTheme(tokens)

  const theme = createTheme(brandedTheme, { tokens })

  return (
    <TrackingProvider experience={{ name, version }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </TrackingProvider>
  )
}

export default MuiThemeProvider
