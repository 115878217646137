import { defineMessages } from 'react-intl';

const baseId = 'hgc-bmi';

export const messages = defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'BMI',
  },
});
