import {
  getSingleValueComponent,
  getLineComponent,
  ComponentConfig,
} from '../helpers';
import { messages } from './messages';

const config: ComponentConfig = {
  dataSource: 'HEIGHT',
  visualisationSettings: { title: messages.title, overrideTitle: true },
};

export const HeightSingle = getSingleValueComponent(config);

export const HeightLine = getLineComponent(config);
