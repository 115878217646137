import { Card, CardContent, Alert } from '@babylon/portal-ui';
import { FC } from 'react';
import { DataResolver } from '../Data';
import { DisplayComponentProps, HGCProps } from '../types';
import { Visualisations } from '../Visualisations';

const DefaultError = (props: { message: string }) => (
  <Alert severity={'error'}>{props.message}</Alert>
);

const DefaultLoader = () => <div>Loading...</div>;

export const HGC = <T,>(props: HGCProps<T>) => {
  const {
    dataProperties,
    dataSource,
    dataSourceSettings,
    visualisationType,
    visualisationSettings,
    additionalDataTransforms,
    queryContext,
  } = props;
  const visualisationConfig = Visualisations[visualisationType];

  const RenderComponent: FC<DisplayComponentProps<T>> = (props) => {
    const { loading, error, data } = props;
    const Loader = visualisationConfig?.LoaderComponent || DefaultLoader;
    const Error = visualisationConfig?.ErrorComponent || DefaultError;
    const Visualisation =
      visualisationConfig?.VisualisationComponent || undefined;

    if (visualisationConfig === undefined) {
      return <Error message="No Visualisation Config found" />;
    } else {
      return loading ? (
        <Loader />
      ) : error ? (
        <Error message={error} />
      ) : Visualisation === undefined || data === undefined ? (
        <Error message="Some Error Occurred" />
      ) : (
        <Visualisation
          data={data}
          visualisationSettings={visualisationSettings}
        />
      );
    }
  };

  const testId = `hgc-chart-${visualisationType}-${dataSource}`;

  return (
    <Card data-testid={testId} sx={{ height: '100%' }}>
      <CardContent>
        <DataResolver<T>
          dataSource={dataSource}
          dataSourceSettings={dataSourceSettings}
          dataProperties={dataProperties}
          Display={RenderComponent}
          additionalDataTransforms={additionalDataTransforms}
          queryContext={queryContext}
        />
      </CardContent>
    </Card>
  );
};
