import { Shape } from './types';
import React from 'react';

export const DataPoint = (props: { shape: Shape; x?: number; y?: number }) => {
  switch (props.shape) {
    case 'circle':
      return (
        <svg x={props.x! - 5} y={props.y! - 5} height="10" width="10">
          <circle cx="50%" cy="50%" r="4" />
        </svg>
      );
    case 'square':
      return (
        <svg width="10" height="10" x={props.x! - 5} y={props.y! - 5}>
          <rect width="8" height="8" x="1" y="1" />
        </svg>
      );
    default:
      return (
        <svg height="100" width="100">
          <circle r="2" />
        </svg>
      );
  }
};

export default DataPoint;
