/**
 * Do not edit directly
 * Generated on Thu, 11 Aug 2022 14:02:26 GMT
 */

export const DARK = false;
export const COLORS_BRAND_PRIMARY = "#0079C8";
export const COLORS_BRAND_PRIMARY_FOCUS = "#0D1846";
export const COLORS_BRAND_SECONDARY = "#0652AE";
export const COLORS_BRAND_TERTIARY = "#D02670";
export const COLORS_TEXT_HEADING = "#21272A";
export const COLORS_TEXT_BODY = "#21272A";
export const COLORS_TEXT_INVERTED = "#FFFFFF";
export const COLORS_STATUS_SUCCESS = "#1B883C";
export const COLORS_STATUS_WARNING = "#C85204";
export const COLORS_STATUS_ERROR = "#D60023";
export const COLORS_FUNCTIONAL_INACTIVE = "#BDC3C9";
export const COLORS_FUNCTIONAL_GRAPHIC = "#929BA2";
export const COLORS_FUNCTIONAL_DIVIDER = "#DDE1E6";
export const COLORS_FUNCTIONAL_INVERTED = "#FFFFFF";
export const COLORS_FUNCTIONAL_OVERLAY = "rgba(0,0,0,0.4)";
export const COLORS_BACKGROUND_BACKGROUND_PRIMARY = "#FFFFFF";
export const COLORS_BACKGROUND_BACKGROUND_SECONDARY = "#F3F5F8";
export const COLORS_BACKGROUND_BUTTON_SECONDARY = "#DDEDF8";
export const COLORS_BACKGROUND_BANNER_INTERACTIVE = "#DDEDF8";
export const COLORS_BACKGROUND_BANNER_ERROR = "#FCECF4";
export const COLORS_BACKGROUND_BANNER_SUCCESS = "#DEFBE6";
export const COLORS_BACKGROUND_BANNER_WARNING = "#FBEACF";
export const COLORS_BACKGROUND_BANNER_NEUTRAL = "#EBEEF2";
export const RADIUS_0 = "0rem";
export const RADIUS_1 = "0.25rem";
export const RADIUS_2 = "0.5rem";
export const RADIUS_3 = "0.75rem";
export const RADIUS_4 = "1rem";
export const RADIUS_CIRCLE_0 = "50%";
export const SPACING_0 = "0rem";
export const SPACING_1 = "0.125rem";
export const SPACING_2 = "0.25rem";
export const SPACING_3 = "0.5rem";
export const SPACING_4 = "0.75rem";
export const SPACING_5 = "1rem";
export const SPACING_6 = "1.25rem";
export const SPACING_7 = "1.5rem";
export const SPACING_8 = "1.75rem";
export const SPACING_9 = "2rem";
export const SPACING_10 = "2.25rem";
export const SPACING_11 = "3rem";
export const SPACING_12 = "3.25rem";
export const SPACING_13 = "3.75rem";
export const SPACING_14 = "4.5rem";
export const SPACING_15 = "6rem";
export const SPACING_16 = "7.5rem";
export const TYPOGRAPHY_HEADING_XXL_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_XXL_WEIGHT = "900";
export const TYPOGRAPHY_HEADING_XXL_SIZE = "40px";
export const TYPOGRAPHY_HEADING_XXL_LINE_HEIGHT = "48px";
export const TYPOGRAPHY_HEADING_XL_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_XL_WEIGHT = "900";
export const TYPOGRAPHY_HEADING_XL_SIZE = "36px";
export const TYPOGRAPHY_HEADING_XL_LINE_HEIGHT = "40px";
export const TYPOGRAPHY_HEADING_LG_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_LG_WEIGHT = "900";
export const TYPOGRAPHY_HEADING_LG_SIZE = "32px";
export const TYPOGRAPHY_HEADING_LG_LINE_HEIGHT = "40px";
export const TYPOGRAPHY_HEADING_MD_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_MD_WEIGHT = "900";
export const TYPOGRAPHY_HEADING_MD_SIZE = "28px";
export const TYPOGRAPHY_HEADING_MD_LINE_HEIGHT = "32px";
export const TYPOGRAPHY_HEADING_SM_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_SM_WEIGHT = "600";
export const TYPOGRAPHY_HEADING_SM_SIZE = "20px";
export const TYPOGRAPHY_HEADING_SM_LINE_HEIGHT = "28px";
export const TYPOGRAPHY_HEADING_XS_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_XS_WEIGHT = "600";
export const TYPOGRAPHY_HEADING_XS_SIZE = "18px";
export const TYPOGRAPHY_HEADING_XS_LINE_HEIGHT = "24px";
export const TYPOGRAPHY_HEADING_XXS_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_XXS_WEIGHT = "600";
export const TYPOGRAPHY_HEADING_XXS_SIZE = "16px";
export const TYPOGRAPHY_HEADING_XXS_LINE_HEIGHT = "24px";
export const TYPOGRAPHY_HEADING_XXXS_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_HEADING_XXXS_WEIGHT = "500";
export const TYPOGRAPHY_HEADING_XXXS_SIZE = "14px";
export const TYPOGRAPHY_HEADING_XXXS_LINE_HEIGHT = "20px";
export const TYPOGRAPHY_BODY_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_BODY_WEIGHT = "400";
export const TYPOGRAPHY_BODY_SIZE = "16px";
export const TYPOGRAPHY_BODY_LINE_HEIGHT = "24px";
export const TYPOGRAPHY_CAPTION_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_CAPTION_WEIGHT = "400";
export const TYPOGRAPHY_CAPTION_SIZE = "14px";
export const TYPOGRAPHY_CAPTION_LINE_HEIGHT = "20px";
export const TYPOGRAPHY_LINK_STANDALONE_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_LINK_STANDALONE_WEIGHT = "600";
export const TYPOGRAPHY_LINK_STANDALONE_SIZE = "16px";
export const TYPOGRAPHY_LINK_STANDALONE_LINE_HEIGHT = "24px";
export const TYPOGRAPHY_LINK_INLINE_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_LINK_INLINE_WEIGHT = "400";
export const TYPOGRAPHY_LINK_INLINE_SIZE = "16px";
export const TYPOGRAPHY_LINK_INLINE_LINE_HEIGHT = "24px";
export const TYPOGRAPHY_LINK_INLINE_DECORATION = "underline";
export const TYPOGRAPHY_LINK_CAPTION_TYPEFACE = "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const TYPOGRAPHY_LINK_CAPTION_WEIGHT = "400";
export const TYPOGRAPHY_LINK_CAPTION_SIZE = "14px";
export const TYPOGRAPHY_LINK_CAPTION_LINE_HEIGHT = "20px";
export const TYPOGRAPHY_LINK_CAPTION_DECORATION = "underline";