import {
  VictoryGroup,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import { Series } from './types';
import { DataPoint } from './DataPoint';

export const renderSeries = (series: Series) => {
  return (
    <VictoryGroup key={series.label}>
      <VictoryArea
        name="areaPlot"
        data={series.plots}
        style={{
          data: {
            fill: series.color,
            fillOpacity: 0.1,
            strokeWidth: 0,
          },
        }}
      />
      <VictoryLine
        name="linePlot"
        data={series.plots}
        style={{ data: { stroke: series.color } }}
        labelComponent={<VictoryTooltip />}
      />
      <VictoryScatter
        name="scatterPlot"
        data={series.plots}
        style={{
          data: {
            fill: series.color,
            stroke: series.color,
            strokeWidth: 1,
            strokeOpacity: 1,
          },
        }}
        dataComponent={<DataPoint shape={series.shape} />}
      />
    </VictoryGroup>
  );
};
