import { GraphName } from '@babylon/web-platform-utils-react/lib/cjs';
import { useState, useEffect } from 'react';
import {
  GetPatientObservationsQueryHookResult,
  GetPatientObservationsQueryVariables,
  ObservationFilter,
  ObservationSortOption,
  useGetPatientObservationsQuery,
} from '../generated/federated';
import {
  DataResolverProps,
  DataSourceConfig,
  DataSourceSettings,
  ProcessedObservation,
} from '../types';
import { getObservations, processObservation } from './observableTransformer';
import { DataSources } from './DataSources';
import { DataProperties } from '../types';

const getQueryVariables = (
  dataSourceConfig: DataSourceConfig,
  dataProperties: DataProperties,
  dataSourceSettings?: DataSourceSettings
): GetPatientObservationsQueryVariables => {
  const variables: GetPatientObservationsQueryVariables = {
    patientId: dataProperties.patientId || '',
    first: dataSourceSettings?.first || 1,
  };

  const filter: ObservationFilter = {};

  if (dataSourceConfig.FHIR_CODE) {
    filter.code = {
      ONE_OF: [
        { code: dataSourceConfig.FHIR_CODE, system: 'https://bbl.health' },
      ],
    };
  }

  if (dataProperties.provenance) {
    filter.provenanceEnterer = {
      ONE_OF: dataProperties.provenance,
    };
  }

  variables.filter = filter;
  variables.sort = [{ field: ObservationSortOption.Date }];
  return variables;
};

export const DataResolver = <T,>(props: DataResolverProps<T>) => {
  const {
    dataSource,
    dataSourceSettings,
    dataProperties,
    additionalDataTransforms,
    Display,
    queryContext,
  } = props;

  const dataSourceConfig = DataSources[dataSource];
  if (dataSourceConfig === undefined) {
    throw new Error('Data Source Not Found');
  }

  const dataTransform = dataSourceConfig?.dataTransform;

  const queryVariables = getQueryVariables(
    dataSourceConfig,
    dataProperties,
    dataSourceSettings
  );

  const { data, loading, error }: GetPatientObservationsQueryHookResult =
    useGetPatientObservationsQuery({
      variables: queryVariables,
      context: queryContext
        ? queryContext
        : {
            graphName: GraphName.FederatedGraph,
          },
    });

  const [processedError, setProcessedError] = useState<string | undefined>(
    undefined
  );

  const [transformedData, setTransformedData] = useState<
    Array<T> | Array<ProcessedObservation> | undefined
  >(undefined);

  useEffect(() => {
    if (error) {
      setProcessedError(error?.message || 'Some Error Occurred');
    }
  }, [error]);

  useEffect(() => {
    if (!data || error) {
      return;
    }

    //reset error when 'new' data is in
    setProcessedError(undefined);

    try {
      const processedNodes: Array<ProcessedObservation> =
        getObservations(data).map(processObservation);

      let transformed = dataTransform
        ? dataTransform(processedNodes)
        : processedNodes;

      if (additionalDataTransforms?.length) {
        additionalDataTransforms.forEach((additionalDataTransform) => {
          transformed = additionalDataTransform(transformed);
        });
      }
      setTransformedData(transformed);
    } catch {
      setProcessedError('Could not apply transform data');
    }
  }, [additionalDataTransforms, data, dataTransform, error]);

  return (
    <Display loading={loading} error={processedError} data={transformedData} />
  );
};
