import { defineMessages } from 'react-intl';

const baseId = 'hgc-smoking-status';

export const messages = defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Smoking Status',
  },
});
