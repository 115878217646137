import * as Theme from '../../build/telus/darkTheme'
import { DefaultTheme } from './DefaultTheme'

export const DarkTheme = {
  ...DefaultTheme,
  dark: true,
  colors: {
    brand: {
      primary: Theme.COLORS_BRAND_PRIMARY,
      primaryFocus: Theme.COLORS_BRAND_PRIMARY_FOCUS,
      secondary: Theme.COLORS_BRAND_SECONDARY,
      tertiary: Theme.COLORS_BRAND_TERTIARY,
    },
    text: {
      heading: Theme.COLORS_TEXT_HEADING,
      body: Theme.COLORS_TEXT_BODY,
      inverted: Theme.COLORS_TEXT_INVERTED,
    },
    status: {
      success: Theme.COLORS_STATUS_SUCCESS,
      warning: Theme.COLORS_STATUS_WARNING,
      error: Theme.COLORS_STATUS_ERROR,
    },
    functional: {
      inactive: Theme.COLORS_FUNCTIONAL_INACTIVE,
      graphic: Theme.COLORS_FUNCTIONAL_GRAPHIC,
      inverted: Theme.COLORS_FUNCTIONAL_INVERTED,
      divider: Theme.COLORS_FUNCTIONAL_DIVIDER,
    },
    background: {
      backgroundPrimary: Theme.COLORS_BACKGROUND_BACKGROUND_PRIMARY,
      backgroundSecondary: Theme.COLORS_BACKGROUND_BACKGROUND_SECONDARY,
      buttonSecondary: Theme.COLORS_BACKGROUND_BUTTON_SECONDARY,
      bannerInteractive: Theme.COLORS_BACKGROUND_BANNER_INTERACTIVE,
      bannerError: Theme.COLORS_BACKGROUND_BANNER_ERROR,
      bannerSuccess: Theme.COLORS_BACKGROUND_BANNER_SUCCESS,
      bannerWarning: Theme.COLORS_BACKGROUND_BANNER_WARNING,
      bannerNeutral: Theme.COLORS_BACKGROUND_BANNER_NEUTRAL,
    },
  },
}
