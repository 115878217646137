import React from 'react'
import { Box, useCheckbox, UseCheckboxProps, useRadio } from '@chakra-ui/react'
import { Chip } from '.'

export type CheckboxChipProps = UseCheckboxProps & {
  children: string
  isRadio?: boolean
}

export function CheckboxChip(props: CheckboxChipProps) {
  const { getInputProps: getRadioInputProps, getCheckboxProps: getRadioProps } =
    useRadio(props)
  const { getInputProps: getCheckInputProps, getCheckboxProps } =
    useCheckbox(props)
  const input = props.isRadio ? getRadioInputProps() : getCheckInputProps()
  const checkbox = props.isRadio ? getRadioProps() : getCheckboxProps()
  const name = props.isRadio ? props.name : props.children
  return (
    <Box as="label">
      <input {...input} name={name} aria-label={props.children} />
      <Chip {...checkbox} selected={props.isChecked}>
        {props.children}
      </Chip>
    </Box>
  )
}
