import {
  BaseLinePeakFlowSingle,
  BloodOxygenSingle,
  BloodSugarSingle,
  BMISingle,
  HbA1cSingle,
  HeightSingle,
  PeakFlowSingle,
  TemperatureSingle,
  WaistCircumferenceSingle,
  WeightSingle,
  BaseLinePeakFlowLine,
  BloodOxygenLine,
  BloodSugarLine,
  BMILine,
  HbA1cLine,
  HeightLine,
  PeakFlowLine,
  SmokingStatusSingle,
  TemperatureLine,
  WaistCircumferenceLine,
  WeightLine,
  BloodPressureSingle,
  BloodPressureLine,
} from './components';

// This file acts as the entry point for the deployed package
export * from './components';
export * from './Data';

export const SingleGraphComponents = {
  BaseLinePeakFlowSingle,
  BloodOxygenSingle,
  BloodPressureSingle,
  BloodSugarSingle,
  BMISingle,
  HbA1cSingle,
  HeightSingle,
  PeakFlowSingle,
  SmokingStatusSingle,
  TemperatureSingle,
  WaistCircumferenceSingle,
  WeightSingle,
};

export const LineGraphComponents = {
  BaseLinePeakFlowLine,
  BloodOxygenLine,
  BloodPressureLine,
  BloodSugarLine,
  BMILine,
  HbA1cLine,
  HeightLine,
  PeakFlowLine,
  TemperatureLine,
  WaistCircumferenceLine,
  WeightLine,
};

export const GraphComponents = {
  ...SingleGraphComponents,
  ...LineGraphComponents,
};

export type SingleGraphType = keyof typeof SingleGraphComponents;
export type LineGraphType = keyof typeof LineGraphComponents;
export type GraphType = keyof typeof GraphComponents;
