import {
  getSingleValueComponent,
  getLineComponent,
  ComponentConfig,
} from '../helpers';
import { messages } from './messages';

const config: ComponentConfig = {
  dataSource: 'HBA1C',
  visualisationSettings: { title: messages.title, overrideTitle: true },
};

export const HbA1cSingle = getSingleValueComponent(config);

export const HbA1cLine = getLineComponent(config);
