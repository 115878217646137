import { ProcessedObservation } from '../../types';

const systolic_code = 'yP1EGhN9d8';
const diastolic_code = 'kETZtQ0TFK';

export const getValuesFromComponents = (
  components: Array<ProcessedObservation>
): {
  systolic: string | number | undefined;
  diastolic: string | number | undefined;
} => {
  const returnValues: {
    systolic: string | number | undefined;
    diastolic: string | number | undefined;
  } = {
    systolic: undefined,
    diastolic: undefined,
  };

  returnValues.systolic = components.find(
    (component) => component.observation_code === systolic_code
  )?.quantity_value;

  returnValues.diastolic = components.find(
    (component) => component.observation_code === diastolic_code
  )?.quantity_value;

  return returnValues;
};
